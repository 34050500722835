import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Discord from "./Discord";
import { terms } from "./Terms"
// import axios from "axios";

// const CONTROLLER = require('../controllers/nftAssetsController.js');

const NFTerms = () => {
  const [termList, setTermList] = useState([]);
  const [filteredTermList, setFilteredTermList] = useState([]);

  useEffect(() => {
    // set placeholder trending NFTs for now
    setTermPlaceholder();
  }, []);

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    console.log(value);
    result = termList.filter((data) => {
      return data.name.toLowerCase().search(value) !== -1;
    });
    console.log(result);
    setFilteredTermList(result);
  };

  const setTermPlaceholder = () => {
    setFilteredTermList(terms);
    setTermList(terms);
  };

  return (
    <Container style={{ marginTop: "25px" }}>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h1
            style={{ color: "#cc7000", fontWeight: "bold", fontSize: "400%" }}
          >
            {" "}
            Keep up with NFT vocab{" "}
          </h1>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={1}></Col>
        <Col xs={10}>
          <input
            type="text"
            style={{
              width: "100%",
              fontSize: "200%",
              borderRadius: "5px",
              borderColor: "transparent",
            }}
            placeholder={"Search term..."}
            onChange={(event) => handleSearch(event)}
          />
        </Col>
        <Col xs={1}></Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col md={1}></Col>
        <Col md={7}>
          {filteredTermList.map((term) => {
            return (
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "10px",
                  marginBottom: "2%",
                }}
              >
                <Col>
                  <h3 style={{ fontWeight: "bold" }}> {term.name} </h3>
                  <p> {term.definition} </p>
                  <p style={{ fontStyle: "italic" }}> {term.sentence} </p>
                </Col>
              </div>
            );
          })}
        </Col>
        <Col md={3}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "#F2F2F2",
              borderRadius: "10px",
              marginBottom: "2%",
            }}
          >
            <p>
              {" "}
              This term list is inspired by @punk6529's <a href="https://twitter.com/punk6529/status/1433002033242595338?s=20"> curated list</a> on Twitter.
              We built it like so wagmi{" "}
            </p>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br />
      <br />
      <Discord />
    </Container>
  );
};

export default NFTerms;
