export const terms = [
  { name: "Ape", definition: "A person who took on a large position relative to his/her portfolio size.", sentence: "That ape just bought a NFTheme, I don't know how these people do it." },
  { name: "DAO", definition: "Decentralized Autonomous Organization, or simply an organization controlled by its members.", sentence: "Only members can make the rules in DAOs." },
  { name: "Shill", definition: "Promoting a project or coin publicly for personal gain.", sentence: "I just shilled NFThings in all the Discords I'm in." },
  { name: "WAGMI", definition: "We're All Gonna Make It.", sentence: "Someone bought a NFTheme, WAGMI!" },
  { name: "NGMI", definition: "Not Gonna Make It.", sentence: "I made a bad decision. NGMI." },
  { name: "GM", definition: "Wishing someone a good morning.", sentence: "GM everyone, hope you all have a good day!" },
  { name: "GN", definition: "Wishing someone a good night.", sentence: "GN everyone, talk to you all in the morning!" },
  { name: "NFA", definition: "Not Financial Advice.", sentence: "I bought some NFThemes, but NFA." },
  { name: "DYOR", definition: "Do Your Own Research.", sentence: "If you don't trust me then DYOR." },
  { name: "Maxi", definition: "Short for 'maximalist', or someone who believes something is superior to everything else.", sentence: "Wow, these Bitcoin Maxis really think Bitcoin is way better than the other cryptocurrencies." },
  { name: "Ser", definition: "Another word for 'sir'.", sentence: "Good ser, do you have the time?" },
  { name: "Fren", definition: "Shortened version of 'friend'.", sentence: "Hello fren, haven't talked to you in so long!" },
  { name: "FOMO", definition: "Fear of Missing Out.", sentence: "I was going to wait to get a NFTheme, but my FOMO convinced me to get one." },
  { name: "Szn", definition: "Shortened version of 'season'.", sentence: "It's bulking szn." },
  { name: "IRL", definition: "In Real Life.", sentence: "The physics in action movies would not exist IRL." },
  { name: "Liquidity", definition: "Funds available to buy something, like cash or ETH.", sentence: "My liquidity hit an all-time low after all the snacks I bought the other day." },
  { name: "Mint", definition: "Adding a piece of art to the blockchain.", sentence: "That is a great NFTheme, mint it!" },
  { name: "OG", definition: "Original Gangster, or simply 'original'.", sentence: "That is the OG NFTheme, all these others are fakes." },
  { name: "Alpha", definition: "Another word for 'info'.", sentence: "That's some serious alpha..." },
  { name: "HODL", definition: "HOLD or Hold On for Dear Life.", sentence: "I know ETH is trending downwards right now, but HODL for the gains in the long term." },
  { name: "FUD", definition: "Fear, Uncertainty, and Doubt.", sentence: "I'm going to buy a NFTheme despite on the FUD that is out there." },
  { name: "Rekt", definition: "Short for 'wrecked', or simply when someone loses a lot of money.", sentence: "I'm getting #rekt by BTC right now." },
  { name: "Whale", definition: "Someone who owns 5% or more of a coin.", sentence: "Where do these Bitcoin whales get their money from?" },
];
