import React from "react";
import { Button, Image } from "react-bootstrap";
import DiscordLogo from "../images/discord.svg";
import "../styles/App.css";

export default function Discord() {
  return (
    <div className="topright drop">
      <a href="https://discord.gg/grKCpPv8Ue">
        <Button
          id="overlay-button"
          variant="light"
          size="sm"
          style={{ backgroundColor: "transparent", borderColor: "transparent" }}
        >
          Discord{" "}
          <Image src={DiscordLogo} alt="Discord Logo" style={{ height: 20 }} />{" "}
        </Button>
      </a>
      |
      <a href="https://nfthings.app/">
        <Button
          id="overlay-button"
          variant="light"
          size="sm"
          style={{ backgroundColor: "transparent", borderColor: "transparent" }}
        >
          {" "}
          NFThings 💡
        </Button>
      </a>
    </div>
  );
}
