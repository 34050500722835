import "./styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect } from "react";

import NFTerms from "./views/NFTerms.js";
import NFTermsNavbar from "./views/NFTermsNavbar.js";
import { Mixpanel } from "./util/Mixpanel";

function App() {
  useEffect(() => {
    Mixpanel.track("NFTerms visited");
  }, []);

  return (
    <body>
      <NFTermsNavbar />
      <NFTerms />
    </body>
  );
}

export default App;
