import {
  Container,
  Navbar
} from 'react-bootstrap';

const NFTermsNavbar = () => {
  return (
    <Container fluid>
      <Navbar>
        <Container fluid>
          <Navbar.Brand href="/"> 📜 NFTerms </Navbar.Brand>
        </Container>
      </Navbar>
    </Container>
  );
}

export default NFTermsNavbar;
